import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "@accrosoft-ltd/accropress-websites-components/dist/esm/seo"
import WigLoader from "@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader"

import { Link } from "gatsby"

import config from "../../config.json"

import "../components/wig-bundle.css"

require("es6-promise").polyfill()

export default function DefaultPage({
  pageContext: {
    integratorData,
    contentMetaData,
    contentDataData,
    navigationData,
    navResultData,
    entireLocationsList,
    customFieldsJSON,
  },
  props,
}) {
  return (
    <Layout {...props}>
      <SEO
        navResultData={navResultData}
        contentDataData={contentDataData}
        integratorData={integratorData}
        contentMetaData={contentMetaData}
      />

      <div className="bannerWrapper">
        <div
          className="st-philips-banner-image"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "relative",
            backgroundImage: `${
              contentDataData[0].children[1].value
                ? `url(${contentDataData[0].children[1].value})`
                : `https://cdn2.accropress.com/theme-assets/StPhilips/hero-images/${contentDataData[0].children[0].value}.jpg`
            }`,
          }}
        ></div>
        <div className="bannerTextWrapper">
          <div className="bannerText">
            <div className="bannerContent">
              <h1>{contentDataData[0].children[0].value}</h1>
            </div>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          class="hb-curve"
        >
          <polygon fill="#fff" points="0,100 100,0 100,100"></polygon>
        </svg>
      </div>

      <div className="page__header-container page__header-container--blog-landing">
        <div className="container">
          <div
            className="row justify-content-center page__header page__header--landing blog-landing__header page__header--blog-landing"
            id="care-homes"
          >
            <div className="col-12 col-lg-8">
              <WigLoader
                weducWebsitesID={config.weducWebsitesID}
                weducContainer={config.weducContainerID}
                weducEntity={config.weducEntityID}
                contentDataData={contentDataData}
                contentMetaData={contentMetaData}
                contentDataArray="0"
                contentDataChild="2"
                AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                integratorID={config.integratorID}
                integratorAccessToken={config.integratorAccessToken}
                imageFormatting={
                  process.env.IMAGE_FORMATTING_SECRET ||
                  process.env.GATSBY_IMAGE_FORMATTING_SECRET
                }
              />
            </div>

            <div className="col-12 col-lg-4">
              <div className="sidebar">
                <h3>Home Details</h3>
                <div className="wrapper">
                  <div className="telephone-number">
                    {contentDataData[0].children[3].value}
                  </div>
                  <div className="email-address">
                    <a href={`mailto:${contentDataData[0].children[4].value}`}>
                      {contentDataData[0].children[4].value}
                    </a>
                  </div>
                  <div className="address">
                    {contentDataData[0].children[5].value}
                  </div>
                  <div className="care-home-details-actions">
                    {customFieldsJSON &&
                    customFieldsJSON[0] &&
                    customFieldsJSON[0].children &&
                    customFieldsJSON[0].children[0] &&
                    customFieldsJSON[0].children[0].value ? (
                      <Link
                        className="location-card-link btn"
                        to={
                          "/vacancies?sublocation=" +
                          contentDataData[0].children[0].value +
                          "&location=" +
                          customFieldsJSON[0].children[0].value
                        }
                      >
                        View vacancies
                      </Link>
                    ) : (
                      <Link
                        className="location-card-link btn"
                        to={
                          "/vacancies?sublocation=" +
                          contentDataData[0].children[0].value
                        }
                      >
                        View all vacancies
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
